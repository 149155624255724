import { performanceSet as performanceSetUrl } from '#/js/config/javaApi.json';

export default {
    checkOpenStatus() {
        return _request({
            method: 'GET',
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            url: performanceSetUrl.check_open_status,
        })
    },

    setOpenStatus(param = { isOpen } = {}) {
        return _request({
            method: 'POST',
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            url: performanceSetUrl.open_calculation,
            data: {...param}
        })
    },

    getSettingList() {
        return _request({
            method: 'GET',
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            url: performanceSetUrl.get_list,
        })
    },

    addOrEditSetting(param = { id, name, defaultUser, type, value, stauts, canModifyDefaultUser, canModifyPercentValue, isMulti } = {}) {
        let url;
        if(param.id) {
            url = performanceSetUrl.edit_setting;
        }else {
            url = performanceSetUrl.add_setting;
        }
        return _request({
            method: 'POST',
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            url: url,
            data: {...param}
        })
    },

    deleteSetting(param = { id } = {}) {
        return _request({
            method: 'DELETE',
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            url: performanceSetUrl.delete_setting,
            data: {...param}
        })
    },

    editSettingStatus(param = { id, status} = {}) {
        return _request({
            method: 'POST',
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            url: performanceSetUrl.edit_setting_status,
            data: {...param}
        })
    },
    /** 饼图数据分布 */
    trackingListDistribution(param) {
        return _request({
            method: 'POST',
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            url: performanceSetUrl.tracking_list_distribution,
            data: param
        })
    },
    // 获取综合协调人客户列表
    getGeneralCoordinatorCustomer(param) {
        return _request({
            method: 'GET',
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            url: performanceSetUrl.general_coordinator_customer,
            data: param
        })
    },

     // 获取进展报表
     getRecommendProcessDetail (param) {
        return _request({
            method: 'POST',
            baseURL: "LbdJavaApi",
            javaProject: "performance",
            url: performanceSetUrl.get_recommendation_progress_detail,
            data: param
        })
    },
     // 导出进展报表
     exportRecommendProcessDetail (param) {
        return _request({
            method: 'POST',
            baseURL: "LbdJavaApi",
            javaProject: "performance",
            url: performanceSetUrl.export_recommendation_progress_detail,
            responseType: "blob",
            data: param
        })
    },
}
