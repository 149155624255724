import {my} from '#/js/config/api.json';
import {user as userUrl} from '#/js/config/api.json';
import tool from "#/js/tool";

const URL = {
    // userinfo : '/User/UserInfo',
    userinfo : '/user/userInfo',
    marketExist: '/Market/Exist'
}

export default {
    /**
     * 获取工作台用户卡信息
     * @param userid
     * @returns {*}
     */
    getCardUserInfo : (userid) => {
        if(!userid){
            return Promise.reject('Param Error: userid is undefined!');
        }

        return _request({
            method: 'GET',
            url: my.profile_card.replace(/\%p/ig,userid)
        })
    },
    getMarketExist(){
        return _request({
            method: 'GET',
            url: URL.marketExist,
            throwBusinessError: true
        })
    },
    getSubject(){
        // return _request({
        //     method: 'GET',
        //     url: URL.userinfo
        // });
        return _request({
            method: 'GET',
            baseURL: "LbdJavaApi",
            javaProject: "performance",
            url: URL.userinfo
        });
    },
    /**
     * 职位名片分享弹窗--获取职位名片分享文案
     */
    getMyJobShareCardLinkInfo() {
        return _request({
            url: userUrl.job_share_card_link_info,
            method: 'GET',
            baseURL: "LbdOpenApi",
            throwBusinessError: true
        })
    },
    /**
     * 职位名片分享弹窗--更新职位名片分享文案
     */
    updateJobShareCardInfo(params = {linkHeadline = ""} = {}) {
        return _request({
            url: userUrl.update_job_share_card_info,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
            throwBusinessError: true
        })
    },
    /**
     * 职位名片个人信息弹窗--获取职位名片个人信息
     * @param {
        *  requireBusinessCard: Boolean        true：添加到职位名片;false：单个职位分享
     * }
     */
    getMyJobShareCardInfo(params = {requireBusinessCard} = {}) {
        return _request({
            url: userUrl.my_job_share_card_info,
            method: 'GET',
            data: params,
            baseURL: "LbdOpenApi",
            throwBusinessError: true
        })
    },
    /**
     * 职位名片个人信息弹窗--更新职位名片个人信息
     * @param {
        *  requireBusinessCard: Boolean        true：添加到职位名片;false：单个职位分享
     * }
     */
    updateMyJobShareCardInfo(params = {}) {
        return _request({
            url: userUrl.update_my_job_share_card_info,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
            throwBusinessError: true
        })
    },

    // 上传个人头像    
    userUploadImage(formData) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: userUrl.user_upload_image,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            throwBusinessError: true,
        });
    },
    
}
