<template>
    <el-dialog
        class="add-param-dialog"
        title="提示"
        :visible.sync="visible"
        width="360px"
        :before-close="handleClose">
        <p>{{ text }}</p>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleConfirm" :disabled="isDisabled">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import performanceSetService from '#/js/service/performanceSetService.js';
    export default {
        name: "doubleConfirmDialog",
        data() {
            return {
                visible: false,
                isDisabled: false,
                confirmFn: null,
                text: ''
            }
        },
        methods: {
            show(json) {
                this.visible = true;
                this.text = json.text;
                this.confirmFn = json.confirmFn;
            },
            handleClose() {
                this.name = '';
                this.id = '';
                this.isDisabled = false;
                this.visible = false;
            },
            handleCancel() {
                this.handleClose();
            },
            handleConfirm() {
                this.isDisabled = true;
                this.confirmFn && this.confirmFn().then(() =>{
                    this.handleClose();
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>