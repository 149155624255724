<template>
    <el-dialog
        class="add-param-dialog"
        :title="editId ? '编辑参数' : '新建参数'"
        :visible.sync="visible"
        width="480px"
        :close-on-click-modal="false"                    
        :close-on-press-escape="false"
        :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form" label-width="90px">
            <el-form-item label="参数名称" prop="name">
                <el-input v-model="form.name" placeholder="请填写"></el-input>
            </el-form-item>
            <el-form-item label="默认用户" prop="defaultUser">
                <el-select v-model="form.defaultUser" placeholder="请选择">
                    <el-option v-for="(label, value) in defaultUserMap" :key="value" :label="label" :value="value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="分成方式" prop="type">
                <el-select v-model="form.type" placeholder="请选择">
                    <el-option
                        v-for="(label, type) in typeMap"
                        :key="type"
                        :label="label"
                        :value="type"
                        :disabled="form.defaultUser == 1 && type == 0"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item 
                v-if="form.type == '0'" 
                label="数值" 
                prop="value"
            >
                <el-input v-model="form.value"></el-input>
            </el-form-item>
            <el-form-item 
                v-if="form.type == '1'" 
                label="比例值" 
                prop="value"
                :rules="rules.propertion"
            >
                <el-input class="share-value-input" v-model="form.value"></el-input>
            </el-form-item>
            <el-form-item label="" label-width="0" prop="options" class="add-param-dialog-checkbox">
                <el-checkbox-group v-model="form.options">
                    <el-checkbox class="open-checkbox" label="status" name="options">是否启用</el-checkbox>
                    <el-checkbox label="canModifyDefaultUser" name="options">是否支持修改默认用户</el-checkbox>
                    <el-checkbox label="isMulti" name="options">是否支持填写多个</el-checkbox>
                    <el-checkbox label="canModifyPercentValue" name="options">是否支持修改分成值</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleConfirm" :disabled="isDisabled">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import performanceSetService from '#/js/service/performanceSetService.js';
    export default {
        name: "AddParamDialog",
        data() {
            let self = this;
            var myValid = (rule, value, callback) => {
                if(self.form.type == '0') {
                    if(value == '') {
                        callback(new Error('请输入分成数值'));
                    }else if(isNaN(+value)) {
                        callback(new Error('分成数值必须为数字值'));
                    }else if(+value > 100000000) {
                        callback(new Error('分成数值不能超过100000000'));
                    }else {
                        callback();
                    }
                }else if(self.form.type == '1') {
                    if(value == '') {
                        callback(new Error('请输入分成比例'));
                    }else if(!(+value >= 0 && +value <= 100)) {
                        callback(new Error('分成比例必须为0-100之间的数值'));
                    }else {
                        callback();
                    } 
                }else {
                    callback();
                }
            }
            return {
                visible: false,
                isDisabled: false,
                editId: '',
                form: {
                    name: '',
                    defaultUser: '',
                    type: '',
                    value: '',
                    options: ['canModifyDefaultUser', 'canModifyPercentValue']
                },
                rules: {
                    name: [
                        { required: true, message: '请输入参数名称', trigger: 'blur' },
                        { max: 15, message: '长度不能超过15个字', trigger: 'blur' }
                    ],
                    defaultUser: [
                        { required: true, message: '请输入默认用户', trigger: 'change' },
                    ],
                    type: [
                        { required: true, message: '请选择分成方式', trigger: 'change' },
                    ],
                    value: [
                        { required: true, validator: myValid, trigger:'blur' }
                    ],
                    propertion: [
                        { required: true, validator: this.validatePropertion, trigger: ['blur', 'change'] }
                    ]
                },
            }
        },
        computed: {
            isCFUser() {
                return this.$store.state.user.userInfo.isCFUser;
            },
            defaultUserMap() {
                if(this.isCFUser) {
                    return {
                        0: '无',
                        1: '归属权判断',
                        2: '推荐人',
                        3: '职位发布者',
                        4: '简历创建者',
                        5: '客户BD',
                        6: '推荐接受者',
                    }
                }else {
                    return {
                        0: '无',
                        2: '推荐人',
                        3: '职位发布者',
                        4: '简历创建者',
                        5: '客户BD',
                        6: '推荐接受者',
                    }
                }
            },
            typeMap() {
                if(this.form.defaultUser == '5') {
                    return {
                        0: '手动输入',
                        1: '比例',
                        2: 'BD分成比例'
                    }
                }else {
                    return {
                        0: '手动输入',
                        1: '比例'
                    }
                }
            }
        },
        watch: {
            'form.defaultUser': function(val, oldVal) {
                this.form.type = '';
            }
        },
        methods: {
            reset(){
                this.editId = '';
                this.form = {
                    name: '',
                    defaultUser: '',
                    type: '',
                    value: '',
                    options: ['canModifyDefaultUser', 'canModifyPercentValue']
                };
                this.isDisabled = false;
            },
            show(json) {
                this.visible = true;
                if(json) {
                    this.editId = json.id;
                    let options = [];
                    if(json.status == 1) options.push('status');
                    if(json.canModifyDefaultUser == 1) options.push('canModifyDefaultUser');
                    if(json.canModifyPercentValue == 1) options.push('canModifyPercentValue');
                    if(json.isMulti == 1) options.push('isMulti');
                    let { name, defaultUser, type, value } = json;
                    
                    //由于有watch defaultUser变化，故将defaultUser先行赋值
                    this.form.defaultUser = String(defaultUser);
                    this.$nextTick(() =>{
                        this.form = {
                            name,
                            defaultUser: String(defaultUser),
                            type: String(type),
                            value,
                            options
                        }
                    })
                }else {
                    this.reset();
                }
            },
            handleClose() {
                this.$refs.form.resetFields();
                this.visible = false;
            },
            handleCancel() {
                this.handleClose();
            },
            handleConfirm() {
                this.submitForm();
            },
            submitForm() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        let data = {...this.form};
                        data.status = data.options.indexOf('status') > -1 ? 1 : 0;
                        data.canModifyDefaultUser = data.options.indexOf('canModifyDefaultUser') > -1 ? 1 : 0;
                        data.canModifyPercentValue = data.options.indexOf('canModifyPercentValue') > -1 ? 1 : 0;
                        data.isMulti = data.options.indexOf('isMulti') > -1 ? 1 : 0;
                        delete data.options;
                        if(data.type == '2') {
                            data.value = '15';
                        }
                        if(this.editId) {
                            data.id = this.editId;
                        }
                        console.log(data);

                        this.isDisabled = true;
                        performanceSetService.addOrEditSetting(data)
                            .then(res =>{
                                shortTips(this.editId ? '编辑成功' : '添加成功');
                                this.reset();
                                this.handleClose();
                                this.$emit('refresh-list');
                            })
                    } else {
                        return false;
                    }
                });
            },
            validatePropertion(rule, value, callback) {
                if(isNaN(+value)) {
                    callback(new Error(`请输入合法的比例!`));
                } else {
                    if(+value >= 100 || +value<0) {
                        callback(new Error(`比例范围在0-100之间!`));
                    }
                    if(value && String(value).match(/\.\d*/g)?.[0]?.length>3) {
                        callback(new Error(`最多支持两位小数!`));
                    }
                    callback();
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .add-param-dialog {
        /deep/ .el-select {
            width:100%;
        }
        /deep/ .el-form-item__label {
            padding-right: 20px;
        }

        .share-value-input::after {
            content: '%';
            position: absolute;
            top: 0;
            right: 10px;
        }

        &-checkbox /deep/ {
            .el-checkbox__input.is-checked .el-checkbox__inner {
                background-color: #fff;

                &:after {
                    border-color: $primary;
                }
            }
            .open-checkbox {
                width: 136px;
            }
        }
    }
</style>