var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "add-param-dialog",
      attrs: {
        title: _vm.editId ? "编辑参数" : "新建参数",
        visible: _vm.visible,
        width: "480px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "90px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "参数名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请填写" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "默认用户", prop: "defaultUser" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.defaultUser,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "defaultUser", $$v)
                    },
                    expression: "form.defaultUser",
                  },
                },
                _vm._l(_vm.defaultUserMap, function (label, value) {
                  return _c("el-option", {
                    key: value,
                    attrs: { label: label, value: value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "分成方式", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.typeMap, function (label, type) {
                  return _c("el-option", {
                    key: type,
                    attrs: {
                      label: label,
                      value: type,
                      disabled: _vm.form.defaultUser == 1 && type == 0,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.type == "0"
            ? _c(
                "el-form-item",
                { attrs: { label: "数值", prop: "value" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "value", $$v)
                      },
                      expression: "form.value",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type == "1"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "比例值",
                    prop: "value",
                    rules: _vm.rules.propertion,
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "share-value-input",
                    model: {
                      value: _vm.form.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "value", $$v)
                      },
                      expression: "form.value",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              staticClass: "add-param-dialog-checkbox",
              attrs: { label: "", "label-width": "0", prop: "options" },
            },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.options,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "options", $$v)
                    },
                    expression: "form.options",
                  },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "open-checkbox",
                      attrs: { label: "status", name: "options" },
                    },
                    [_vm._v("是否启用")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { label: "canModifyDefaultUser", name: "options" },
                    },
                    [_vm._v("是否支持修改默认用户")]
                  ),
                  _c(
                    "el-checkbox",
                    { attrs: { label: "isMulti", name: "options" } },
                    [_vm._v("是否支持填写多个")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: {
                        label: "canModifyPercentValue",
                        name: "options",
                      },
                    },
                    [_vm._v("是否支持修改分成值")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.isDisabled },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }