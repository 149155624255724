var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "performance-set" },
    [
      _c("div", { staticClass: "performance-set-top" }, [
        _c(
          "div",
          { staticClass: "performance-set-top-left" },
          [
            _c("el-switch", {
              attrs: {
                disabled: _vm.isSwitchDisabled,
                width: 40,
                "active-color": "#38bc9d",
                "inactive-color": "#999999",
              },
              on: { change: _vm.setOpenStatus },
              model: {
                value: _vm.isOpen,
                callback: function ($$v) {
                  _vm.isOpen = $$v
                },
                expression: "isOpen",
              },
            }),
            _c("span", { staticClass: "performance-set-top-text" }, [
              _vm._v("启用业绩计算"),
            ]),
            _c("i", { staticClass: "performance-set-top-tip" }, [
              _vm._v("若不配置参数，业绩将计算给推荐者"),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "performance-set-top-right" }, [
          _c(
            "div",
            {
              staticClass: "performance-set-top-button",
              on: { click: _vm.handleAdd },
            },
            [
              _c("i", { staticClass: "el-icon-circle-plus-outline" }),
              _vm._v("\n                新增参数\n            "),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "performance-set-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                height: "800",
                "tooltip-effect": "light",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  align: "center",
                  "show-overflow-tooltip": true,
                  label: "参数名称",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "默认用户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.defaultUserMap[scope.row.defaultUser])
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "参数值" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type == 0
                          ? _c("div", [_vm._v(_vm._s(scope.row.value))])
                          : _vm._e(),
                        scope.row.type == 1
                          ? _c("div", [_vm._v(_vm._s(scope.row.value + "%"))])
                          : _vm._e(),
                        scope.row.type == 2
                          ? _c("div", [
                              _vm._v(_vm._s(_vm.typeMap[scope.row.type])),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "是否支持填写多个", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(scope.row.isMulti ? "是" : "否")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "是否支持修改默认用户",
                  align: "center",
                  width: "165",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(scope.row.canModifyDefaultUser ? "是" : "否")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "是否支持修改分成值",
                  align: "center",
                  width: "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row.canModifyPercentValue ? "是" : "否"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(scope.row.status ? "启用" : "关闭")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c(
                              "span",
                              {
                                staticClass: "operation-column-btn enable",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEnableSetting(scope.row)
                                  },
                                },
                              },
                              [_vm._v("启用")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "operation-column-btn disable",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDisableSetting(scope.row)
                                  },
                                },
                              },
                              [_vm._v("关闭")]
                            ),
                        _c("i", [_vm._v("|")]),
                        _c(
                          "span",
                          {
                            staticClass: "operation-column-btn edit",
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("i", [_vm._v("|")]),
                        _c(
                          "span",
                          {
                            staticClass: "operation-column-btn delete",
                            on: {
                              click: function ($event) {
                                return _vm.doDelete(
                                  scope.row.name,
                                  scope.row.id
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "performance-set-footer" }, [
        _vm._v("\n        猎必得 liebide.com\n    "),
      ]),
      _c("add-param-dialog", {
        ref: "addParamDialog",
        on: { "refresh-list": _vm.handleRefreshList },
      }),
      _c("double-confirm", { ref: "doubleConfirm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }